import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Footer from '../components/Footer'
import Header from '../components/Header'
import JoinJ from '../components/JoinJ'

const Layout = () => {
    const route = useLocation();
    console.log("route", route?.pathname !== "/privacy-policy")
    const joinJ = (!route?.pathname.includes('disclaimer') && !route?.pathname.includes('pdf1-thank-you') && !route?.pathname.includes('pdf2-thank-you') && !route?.pathname.includes('pdf3-thank-you') && route?.pathname !== "/term-and-condition" && route?.pathname !== "/privacy-policy" && route !== "/disclaimer") && <JoinJ />
    return (
        <>
            <Header />
            <Outlet />
            {joinJ}
            <Footer />
        </>
    )
}

export default Layout