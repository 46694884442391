import React, { useContext, useState } from 'react'
import HhpInvestImg from "../assets/images/PathToHappiness1.png";
import BBFreeImg from "../assets/images/bottomimage.png";
import MaibnRSImg from "../assets/images/HAMSTER.jpg";
import FfDilImgBn from "../assets/images/SURROUND.png";
import SpCOFfImg from "../assets/images/1stPDF.png";
import SpCOFfImgS from "../assets/images/2ndPDFA.png";
import UstandYPLimg from "../assets/images/smolarz.png";
import MyprnlrightImg from "../assets/images/MyPersonalJourney.png";
import BtnImgFile from "../assets/images/btnImgFile.svg";
import StepPaper from "../assets/images/stepPaper.svg";
import { useForm, Controller } from 'react-hook-form';
import { handlePhoneNumberKeyPress } from '../helper';
import { axiosInstance } from '../http/axios';
import Form from '../components/Form';
import ScrollToTopLink from '../components/ScrollToTopLink';
import CommonModal from '../components/CommonModal';
import { AppContext } from '../AppContext';
import { Link, useNavigate } from 'react-router-dom';

const HomeT = () => {
    const { setLoader, setTitle } = useContext(AppContext)

    const navigate = useNavigate()
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    // for banner form
    const { handleSubmit, register, control, formState: { errors }, reset } = useForm();

    // for query form
    const {
        register: register2,
        formState: { errors: errors2 },
        handleSubmit: handleSubmit2,
        reset: reset2,
        control: control2
    } = useForm();

    const {
        register: register3,
        formState: { errors: errors3 },
        handleSubmit: handleSubmit3,
        reset: reset3,
        control: control3
    } = useForm();

    // download pdf
    const handleDownloadPDF = async (url) => {
        const pdfUrl = 'assets/pdf/Home3.pdf';
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'Top 5 Mistakes to Steer Clear Of.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        navigate("/homeT/pdf3-thank-you");
    }

    const handleGetYourFreeGuide = async (data) => {
        setLoader(true)
        const inputString = data?.name;
        const [firstname, lastname] = inputString.split(/\s(.+)/);
        const { termAndCond, phoneNumber, ...newObject } = data;

        const bodyData = {
            ...newObject,
            firstname: firstname,
            lastname: lastname ? lastname : "",
            type: 'freedom',
            phone: data?.phoneNumber
        };

        try {
            axiosInstance
                .post(`/pdf-guide`, bodyData)
                .then((res) => {
                    console.log("res", res)
                    if (res) {
                        setLoader(false)
                        handleDownloadPDF(res?.data?.url);
                        let originalString = res?.data?.url;
                        let parts = originalString.split('.');
                        let substringBeforeDot = parts[0];
                        setTitle(substringBeforeDot)
                    }
                    if (res.status === 200) {
                        const objData = data;
                        for (const key in objData) {
                            objData[key] = "";
                        }
                        reset2(objData);
                        reset3(objData);

                    }
                    setLoader(false)
                });
        } catch (error) {
            console.log("error", error);
            setLoader(false)
        }
    }

    return (
        <>
            <main className='main_bg'>
                <div className="cusContainer">
                    <div className="row align-items-center">
                        <div className="col-xl-5 col-lg-6 col-md-7">
                            <h2 className='head2'>Attention Doctors: Don't <br />Just Invest for Retirement,<br /> Make Work Optional.</h2>
                            <h5 className='head5'>Avoid the Financial Pitfalls That Keep You On-Call Forever. A Guide Created by a Doctor, for Doctors. </h5>
                            <p className='txt'>Download Our Free Guide & Take the First Step Towards Making Work Optional Today!</p>
                            <form onSubmit={handleSubmit(handleGetYourFreeGuide)}>
                                <div className="main_input">

                                    <div className="inputGroup mt-3">
                                        <div className="inputItems me-lg-2">
                                            <input {...register("name", { required: true })} className='fillIn px-4 requiredStar' placeholder='Name' />
                                            {errors.name && <p className='error'>Name is required</p>}
                                        </div>
                                        <div className="inputItems mt-3">
                                            <input {...register("email", { required: true })} type="email" className='fillIn px-4 requiredStar' placeholder='Email' />
                                            {errors.email && <p className='error'>Email is required</p>}
                                        </div>
                                    </div>
                                    <div className="inputItems mt-3">
                                        <div className="n">
                                            <Controller
                                                name="phoneNumber"
                                                control={control}
                                                rules={{
                                                    required: true,
                                                    pattern: {
                                                        value: /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
                                                        message: 'Invalid phone number format',
                                                    },
                                                }}
                                                render={({ field }) => (
                                                    <input {...field} type="text" maxLength={10} placeholder='Phone Number' className='fillIn px-4' onKeyDown={handlePhoneNumberKeyPress} />
                                                )}
                                            />
                                        </div>
                                        {errors.phoneNumber && <p className='error'>Phone number is required</p>}
                                        {errors.phoneNumber && <p className='error'>{errors.phoneNumber.message}</p>}
                                    </div>
                                    <div className="inputGroup mb-sm-3 mb-2 mt-2">
                                        <div className="d-flex align-items-start HtermCond">
                                            <input type="checkbox" id='checkbox' className='me-2 mt-1' {...register("termAndCond", { required: true })} />
                                            <label htmlFor='checkbox'>I agree to the <Link className='text-primary' to={"/term-and-condition"}>Terms & Conditions</Link>  provided by the company. By providing my phone number, I agree to receive text messages from the business.</label>
                                        </div>
                                        {errors.termAndCond && <p className='error ms-0'>This field is required</p>}
                                    </div>
                                </div>
                                <button type="submit" className="btnC mt-4">Get Your Free Guide Now</button>
                            </form>
                        </div>
                        <div className="offset-xl-1 col-md-5 col-lg-6 text-md-end">
                            <img src={MaibnRSImg} alt="img" className='mainRS_img' />
                        </div>
                    </div>
                </div>
            </main>
            <section className="ffDil">
                <div className="cusContainer">
                    <div className="redTxt">Facing Financial Dilemmas</div>
                    <h2 className='head2 mb30'>A Doctor’s Challenge</h2>
                    <div className="ffDil_banner mb30">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div className="p-lg-4">
                                    <h2 className="head2">Are You Satisfied in <br />Your Practice?</h2>
                                    <p className='txt'>Like you, I once found my power and autonomy eroded by insurance companies, hospital administrations, and even other providers. You've worked hard to achieve your status and enjoy a high salary, but do you feel trapped by golden handcuffs? After 13 years of unfair treatment, I took the power back. Now it's your time to break free. There is no better revenge than success.</p>
                                    <ScrollToTopLink to='contact-us' className="btnC mt-sm-4 mt-3 d-inline-block">Get Your Free Guide Now</ScrollToTopLink>
                                </div>
                            </div>
                            <div className="offset-xl-1 col-xl-5 col-md-6">
                                <div className="ffDil_banner-right mt-3 mt-md-0">
                                    <img src={FfDilImgBn} alt=" img" className='ffDilImgBn' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="whiteBox">
                                <h2 className="head2">The Complex <br />Financial Struggles <br /> Many Doctors Face</h2>
                                <p className='txt mb-0'>
                                    I know the pressure to perform, the long hours, and the lack of control over our financial destiny. I have a history of standing up for fellow residents and doctors, and now I want to help you recognize and overcome these financial challenges.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 mt-md-0 mt-4">
                            <div className="whiteBox">
                                <h2 className="head2"> The Financial <br /> Stress of Unfair  <br /> Treatment at Work</h2>
                                <p className='txt mb-0'>
                                    I've seen firsthand how insurance companies and hospital administrations can take our power away. This constant pressure led me to undue stress, but I aligned my financial goals with my values and fought for my rights. Let's do the same for you.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt30 ">
                        <div className="maxWffdil_mtxt">
                            <h2 className="head2">Your Dedication Deserves a Reward, but Are You Making the Right Financial Choices for Your Future</h2>
                            <p className='txt'>I've learned the value of investing and providing asymmetrical risk. Let me help you avoid common traps and regain control.</p>
                            <ScrollToTopLink to='contact-us' className="btnC mt-sm-4 mt-3 d-inline-block">Request a Call</ScrollToTopLink>
                        </div>

                    </div>
                </div>
            </section>
            <section className="specialOffer">
                <div className="cusContainer">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="redTxt">Special Offer</div>
                            <h2 className="head2">Doctor's Financial Survival Guide: Top 5 Mistakes to Steer Clear Of</h2>
                            <p className='txt mb-0'>Avoid Falling into the Trap of Financial Mistakes That Plague Many Medical Professionals. Like me, you too can arm yourself with the knowledge to embark on a journey toward financial freedom.</p>
                            <Form
                                handleSubmit2={handleSubmit2}
                                handleGetYourFreeGuide={handleGetYourFreeGuide}
                                register2={register2}
                                errors2={errors2}
                                handleShow={handleShow}
                                BtnImgFile={BtnImgFile}
                                handlePhoneNumberKeyPress={handlePhoneNumberKeyPress}
                                control={control2}
                            />

                        </div>
                        <div className="col-md-6">
                            <img src={SpCOFfImg} alt="SpCOFfImg img" className='SpCOFfImg' />
                        </div>
                    </div>
                </div>
            </section>
            <section className="understandYP">
                <div className="cusContainer px-md-0">
                    <h3 className="head3">
                        A PATHWAY TO FINANCIAL FREEDOM: Introducing STOR Self-Storage Fund
                    </h3>
                </div>
                <div className="cusContainer ps-md-0">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img src={UstandYPLimg} alt="img" className='ustandYPLimg' />
                        </div>
                        <div className="col-md-6 mt-3 mt-md-0">
                            <div className="ps-lg-4">
                                <div className="redTxt">I Understand Your Pain</div>
                                <p className='txt'>I am Dr. Joseph Ryan Smolarz. As the owner of my own ENT practice for the past 10 years, I know firsthand the financial challenges we doctors face. Like you, I was once constrained by the medical industry, but I fought back, triumphing over financial adversity. Through strategic investments in commercial real estate, self-storage, and assisted living, I broke free. With an Executive MBA from the University of Miami and a Medical Doctorate, I have both the medical and business understanding to guide your investment journey.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ovSs">
                <div className="cusContainer">
                    <h2 className='head2'>Overview of the Self-Storage Real Estate Investment</h2>
                    <p className='txt mt-4'>The STOR Self-Storage Fund is more than just an investment; it's a direct response to the unique financial struggles we doctors face. Inspired by my own journey, I've created this opportunity for you. The self-storage market may be a suitable way to diversify your portfolio. Together, my team and I have selected properties that balance risk and reward, tailoring them to your desire for financial empowerment.</p>
                    <div className="text-center">
                        <ScrollToTopLink to='contact-us' className="btnC mt-sm-4 mt-3 d-inline-block">Request a Call</ScrollToTopLink>
                    </div>
                </div>
            </section>
            <section className="benSpcDoc">
                <div className="cusContainer">
                    <div className="row">
                        <div className="col-xl-6 col-lg-8 col-md-10">
                            <h2 className="head2">
                                Benefits Specifically<br /> for Doctors
                            </h2>
                            <p className='txt'> The STOR Self-Storage Fund offers an investment avenue tailored with an understanding of the financial landscapes often navigated by doctors. Seeking to provide a potential solution to some of the financial constraints faced by medical professionals, the fund aspires to be a viable option for those exploring diverse investment opportunities. Here's how it can benefit you:</p>
                            <ul className='mb-0'>
                                <li>
                                    <p className='txt mb-0'> I'm a fellow doctor who triumphed over adversity, and I will guide your investment strategy.</p>

                                </li>
                                <li>
                                    <p className='txt mb-0'> I focus on hard assets, providing you with a tangible investment opportunity.</p>

                                </li>
                                <li>
                                    <p className='txt mb-0'> Follow my example and break free from common financial constraints, taking control of your financial future.</p>
                                </li>
                            </ul>

                            <ScrollToTopLink to='contact-us' className="btnC mt-sm-4 mt-3 d-inline-block"> Get Your Free Guide Now</ScrollToTopLink>


                        </div>
                    </div>
                </div>
            </section>
            <section className="specialOffer specialOfferTwo ">
                <div className="cusContainer">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="redTxt">Special Offer</div>
                            <h2 className="head2">Doctor's Financial Survival Guide: Top 5 Mistakes to Steer Clear Of</h2>
                            <p className='txt mb-0'>My journey is about more than investment; it's about education and empowerment. I learned from my experiences, and I want to guide you on your path. I've prepared a special guide to uncover the common financial mistakes that many doctors make. Click the button below to request your free copy and follow in my footsteps towards financial sovereignty.</p>
                            <Form
                                handleSubmit2={handleSubmit3}
                                handleGetYourFreeGuide={handleGetYourFreeGuide}
                                register2={register3}
                                errors2={errors3}
                                handleShow={handleShow}
                                BtnImgFile={BtnImgFile}
                                handlePhoneNumberKeyPress={handlePhoneNumberKeyPress}
                                control={control3}
                            />

                        </div>
                        <div className="col-md-6">
                            <img src={SpCOFfImgS} alt="SpCOFfImg img" className='SpCOFfImg' />
                        </div>
                    </div>
                </div>
            </section>
            <section className="stepbystep">
                <div className="cusContainer">
                    <h3 className="head3">Your Journey to Investment Success: A Step-by-Step Guide</h3>
                    <div className="stepbystep_group mt-4">
                        <div className="stepbystep_item">
                            <div className="stepbystep_icon mb-4">
                                <img src={StepPaper} alt="step icon" />
                            </div>
                            <h5 className="head5">Understanding the <br />Investment Process</h5>
                            <p className='txt'>Embark on an informed investment journey with the STOR Self-Storage Fund. Explore the domain of investing in self-storage real estate, recognizing its historical patterns and growth potential. We've structured the process to facilitate medical professionals, like you, to participate in this distinctive opportunity.</p>

                        </div>
                        <div className="stepbystep_item">
                            <div className="stepbystep_icon mb-4">
                                <img src={StepPaper} alt="step icon" />
                            </div>
                            <h5 className="head5">How to Get <br />Started </h5>
                            <p className='txt'>Getting started is as simple as reaching out to us. With STOR, you're not just another investor; you're part of an exclusive community of doctors building financial strength. Connect with one of our knowledgeable advisors, and we'll guide you every step of the way.</p>

                        </div>
                        <div className="stepbystep_item">
                            <div className="stepbystep_icon mb-4">
                                <img src={StepPaper} alt="step icon" />
                            </div>
                            <h5 className="head5">Long-term Benefits <br />and Flexibility</h5>
                            <p className='txt'>What characterizes STOR is our dedication to offering investment options with a view toward long-term considerations, customized to your preferences. Explore the adaptability and management of an investment designed with your financial objectives in mind. Recognizing the value of your time as a medical professional, we aim to streamline your investing process.</p>

                        </div>
                    </div>
                    <div className="text-center">
                        <ScrollToTopLink to='contact-us' className="btnC mt-sm-4 mt-3 d-inline-block"> Get Your Free Guide Now</ScrollToTopLink>
                    </div>
                </div>
            </section>
            <section className='myprnl'>
                <div className="cusContainer">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h5 className='head5'>My Personal Journey: A Lesson in Breaking Free</h5>
                            <p className='txt'>I didn't just overcome the golden handcuffs; I shattered them. Over the last 6 years, I've embarked on a learning journey in business and investment, mastering strategies that I can now tailor specifically for fellow medical professionals like you. My pursuit of financial independence led to profound personal and professional growth, enabling me to regain happiness and control. My story could be your story.</p>
                            <ScrollToTopLink to='contact-us' className="btnC mt-sm-4 mt-3 d-inline-block">Request a Call</ScrollToTopLink>
                        </div>
                        <div className="offset-lg-1 col-lg-5 col-md-6 mt-3 mt-md-0">
                            <img src={MyprnlrightImg} alt="img" className='MyprnlrightImg' />
                        </div>
                    </div>
                </div>
            </section>
            <section className="hppbnf">


                <div className="cusContainer p-md-0">
                    <div className="d-md-flex imgmobile-c align-items-center">
                        <div className="col-left">
                            <h3 className='head3'>Investing as a Considered Approach: The STOR Self-Storage Fund</h3>
                            <p className='txt'>The notion of "golden handcuffs" often goes beyond mere financial limitations, sometimes permeating one's broader sense of fulfillment. My engagement in investment was not solely financially driven; it also served as a conduit for aligning financial actions with individual values and interests. When I invested in opportunities similar to those anticipated by the STOR Self-Storage Fund, it was a strategic financial decision based on available information and personal interest in the sector. This investment opportunity is crafted with a hope to provide doctors, like myself, an avenue to explore financial decisions that might coincide with their own values and financial objectives, while acknowledging that investment always comes with varied outcomes and inherent risk.</p>
                            <div className="d-flex">
                                <ScrollToTopLink to='contact-us' className="btnC mt-sm-4 mt-3 d-inline-block">
                                    Get Your Free Guide Now
                                </ScrollToTopLink>
                            </div>
                        </div>
                        <div className="col-right">
                            <img src={HhpInvestImg} alt="invetHpp" className='w-100' />
                        </div>
                    </div>
                    <div className="d-md-flex hppbnf_SB imgmobile-c align-items-center">
                        <div className="col-left">
                            <h3 className='head3'>Benefits of Breaking Free</h3>
                            <ul>
                                <li>
                                    <p className='txt'>
                                        Guided by Experience: Engage in an investment journey alongside someone who has traversed similar paths and appreciates the distinct challenges you may encounter.
                                    </p>
                                </li>
                                <li>
                                    <p className='txt'>Considered Strategies: Explore investment approaches shaped with doctors in mind, informed by my six-year journey in business and investment.</p>
                                </li>
                                <li>
                                    <p className='txt'>Explore Control: Consider stepping beyond the constraints of the medical industry to contemplate managing your financial future.</p>
                                </li>
                                <li>
                                    <p className='txt'>Aligning Finances and Values: Allow me to assist you in considering how your financial strategies might align with your values and interests, presenting a possibility for personal and professional satisfaction.</p>
                                </li>
                                <li>
                                    <p className='txt'>Explore new financial pathways with a fund designed with medical professionals in mind - learn more about how the STOR Self-Storage Fund might align with your investment interests and goals.</p>
                                </li>
                            </ul>
                            <div className="d-flex">
                                <ScrollToTopLink to='contact-us' className="btnC mt-sm-4 mt-3 d-inline-block">
                                    Get Your Free Guide Now
                                </ScrollToTopLink>

                            </div>
                        </div>
                        <div className="col-right">

                            <img src={BBFreeImg} alt="BBFreeImg" className='w-100' />

                        </div>

                    </div>
                </div>
            </section>
            <CommonModal
                show={show}
                handleClose={handleClose}
                title="Top 5 Mistakes to Steer Clear Of"
            />
        </>
    )
}

export default HomeT