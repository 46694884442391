import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ScrollToTopLink from './ScrollToTopLink'

const ThankYou = () => {
    const navigate = useNavigate();
    const [pdfTitle, setPdfTitle] = useState();
    const { pathname } = useLocation();
    const [getPath, setPath] = useState();

    useEffect(() => {
        if (pathname.includes('homeS')) {
            setPath('/homeS/');
            setPdfTitle('A Comprehensive Guide')
        } else if (pathname.includes("homeT")) {
            setPath("/homeT/");
            setPdfTitle('Top 5 Mistakes to Steer Clear Of')
        } else {
            setPath("/");
            setPdfTitle("Your Guide to Freedom");
        }
    }, [pathname]);
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, [])
    return (
        <div className="thanku py-lg-5 py-3">
            <div className="cusContainer">
                <div className='modalBoay px-0'>
                    <h2 className="head2 mb-4">Thank you for Downloading!</h2>

                    <h5 className="head5">👨‍⚕️ Hello Doctor!</h5>
                    <p className='txt'>
                        We're thrilled that you've taken the first step towards achieving your
                        financial goals and breaking those golden handcuffs.
                    </p>
                    <h5 className='head5'>📚 Your Free Guide: "{pdfTitle && pdfTitle}" is on Its Way!</h5>
                    <p className='txt'>Please check your email to download your copy. If you don't see it,
                        don't forget to check your spam or promotions folder.</p>
                    <h5 className='head5'>📞 A Personal Invitation from Dr. Smolarz</h5>
                    <p className='txt'>Dr. Smolarz, the financial mentor for doctors like you, would be
                        honored to speak with you directly to discuss your unique financial
                        challenges and potential solutions. Schedule your personal call now
                        to take control of your financial future.</p>
                    <h5 className='head5 mb-3'>👉 <ScrollToTopLink to={`${getPath}contact-us`}>Schedule a Call with Dr. Smolarz</ScrollToTopLink></h5>
                    <h5 className='head5'>🤔 Questions?</h5>
                    <p className='txt'>Our team is always here to help. Feel free to reach out to us at <ScrollToTopLink to={`${getPath}contact-us`} className='linkM'>CONTACT US.</ScrollToTopLink>
                    </p>
                    <p className='txt'>🙏 Thank you for trusting us to be a part of your financial journey.
                        Here's to your success!</p>

                    <div className="modalBoayFtr mt-5 text-center">
                        <ScrollToTopLink to={getPath} onClick={() => navigate(-1)} className='btnC'>OK</ScrollToTopLink>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ThankYou