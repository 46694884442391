import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Logo from "../assets/images/logo.svg";

const Header = () => {

    const { pathname } = useLocation();
    const [getPath, setPath] = useState();

    useEffect(() => {
        if (pathname.includes('homeS')) {
            setPath('homeS/');
        } else if (pathname.includes("homeT")) {
            setPath("homeT/")
        } else {
            setPath("/")
        }
    }, [pathname]);

    return (
        <header>
            <div className="cusContainer">
                <div className="flex_jus_item">
                    <div className='logo'>
                        <NavLink to={getPath}>
                            <img src={Logo} alt=' logo' />
                        </NavLink>
                    </div>
                    <div className="navLink_h">
                        <ul className='mb-0'>
                            <li>
                                <NavLink to={`${getPath}about`} id='about'>About</NavLink>
                            </li>
                            <li>
                                <NavLink to={`${getPath}faq`}>FAQ</NavLink>
                            </li>
                            <li>
                                <NavLink to={`${getPath}contact-us`}>Contact Us</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header