import ReactLoading from "react-loading";

const Loader = () => {
    return (
        <div className="loader-container">
            <ReactLoading color="black" type="spinningBubbles" />
        </div>
    )
}

export default Loader