import React from 'react'

const PrivacyPolicy = () => {
    return (
        <div class="container">
            <h1 style={{ textAlign: "center", marginBottom: "20px" }}>Privacy Policy </h1>
            <p><i>Last updated: September 18, 2023</i></p>
            <p>STOR Management LLC (the “Company,” “we,” “us,” or “our”) is committed to protecting your privacy. Your
                privacy is important to us. It is our policy to respect your privacy and comply with any applicable law and
                regulation regarding any personal information we may collect about you, including across our website
                <a href="https://storpartners.com" target="_blank">storpartners.com</a> and other sites we own and operate
                (collectively,
                the
                “Platform”). This Privacy Policy is
                designed to help you understand how we collect, use, process, and share your personal information, and to
                help you understand and exercise your privacy rights.
            </p>
            <p>In the event our Platform contains links to third-party sites and services, please be aware that those sites
                and services have their own privacy policies. After following a link to any third-party content, you should
                read their posted privacy policy information about how they collect and use personal information. This
                Privacy Policy does not apply to any of your activities after you leave our Platform.</p>
            <h2>INFORMATION WE COLLECT</h2>
            <p>The categories of personal information we collect depend on how you interact with us, our Platform, and the
                requirements of applicable law. We collect information that you provide to us, information we obtain
                automatically when you use our Platform, and information from other sources such as third-party services and
                organizations, as described below.</p>
            <p>We may ask for personal information — for example, when you register an account, make a listing, or when you
                contact us — which may include one or more of the following:
            </p>
            <ul>
                <li>Name </li>
                <li>Email</li>
                <li>Social media profiles</li>
                <li>Date of birth</li>
                <li>Phone/mobile number</li>
                <li>Home/mailing address</li>
                <li>Digital Asset Wallet Address</li>
                <li>Other information necessary for Anti-Money Laundering (AML) and Know Your Customer (KYC) compliance
                    purposes</li>
            </ul>
            <p>We use different methods to collect information from and about you, including through:</p>
            <p><b>Direct interactions.</b> You may give us your identity data, contact data, financial data, account
                data and marketing and communications data by directly interacting with us, including by filling in forms,
                by email or otherwise. This includes personal data you provide when you:</p>
            <ul>
                <li>visit our Platform;</li>
                <li>create an account on our Platform;</li>
                <li>make a listing on our Platform;</li>
                <li>enter into a transaction on our Platform;</li>
                <li>request marketing to be sent to you;</li>
                <li>enter a competition, promotion, or survey, including through social media channels;</li>
                <li>give us feedback or contact us.</li>
            </ul>
            <p><b>Automated technologies or interactions.</b> As you interact with us via our Platform, we may use automatic
                data collection technologies to collect certain information about your equipment, browsing actions, and
                patterns, including but not limited to, your browser type, operating system, Internet Protocol (“IP”)
                address, mobile device ID, blockchain address, wallet type, and date/time stamps</p>
            <p><b>Third parties or publicly available sources.</b> We also obtain information about you from third parties
                or publicly available sources. These sources may include:</p>
            <ul>
                <li>public blockchain from which we collect blockchain addresses and information regarding purchases, sales,
                    or transfers of NFTs, which may then be associated with other data you have provided to us;</li>
                <li>third-party wallet providers that provide us with your blockchain wallet address and certain other
                    information you choose to share with those wallet providers;</li>
                <li>publicly available information on the Internet (websites, articles etc.)</li>
            </ul>
            <h2>COOKIE</h2>
            <p>Like most websites, when you visit the Platform we may store some information (commonly known as a “cookie”)
                on your computer, mobile phone, tablet or other device. Cookies are small text files of information that use
                a unique identification tag and are stored on your device as a result of accessing the Platform or other
                services we provide to you. We use cookies to track site usage and trends, to improve the quality of the
                Platform, and to customize, personalize and enhance your experience on the Platform. These cookies are
                automatically deleted after a defined time. Most browsers accept cookies automatically. However, you can
                configure your browser so that no cookies are stored on your computer or always provide a hint appearing
                before a new cookie is created. However, disabling cookies completely may mean that you cannot use all
                features of the Platform. </p>
            <p>Some web browsers (including Safari, Internet Explorer, Firefox, and Chrome) incorporate a “Do Not Track”
                (“DNT”) or similar feature that signals to websites that a user does not want to have his or her online
                activity and behavior tracked. If a website that responds to a particular DNT signal receives the DNT
                signal, the browser can block that website from collecting certain information about the browser’s User. Not
                all browsers offer a DNT option and DNT signals are not yet uniform. Because there is not yet an accepted
                standard for how to respond to browser DNT signals, we do not currently respond to them. You also can
                typically remove and reject Cookies or Local Storage from the Platform with your browser settings. Many
                browsers are set to accept Cookies and Local Storage until you change your settings. If you remove or reject
                Cookies or Local Storage, it could affect how we provide services to you. In all circumstances, we may
                perform the foregoing information collection practices directly or use a third-party vendor to perform these
                functions on our behalf. Also, if you access the Platform from a social networking service, we may share
                information with such social networking service in accordance with this Privacy Policy and to the extent
                permitted by your agreement with such social networking service and its privacy settings.</p>
            <h2>ANALYTICS SERVICES</h2>
            <p>In addition to the tracking technologies we place, other companies may set their own cookies or similar tools
                when you visit our Platform. This includes third party analytics services, including, but not limited to,
                Google Analytics (“<b>Analytics Services</b>”), that we may engage to help analyze how users use the
                Platform. We
                may receive reports based on these parties’ use of these tools on an individual or aggregate basis. We use
                the information we get from Analytics Services only to improve our Platform. The information generated by
                the Cookies or other technologies about your use of our website (the “<b>Analytics Information</b>”) is
                transmitted
                to the Analytics Services. The Analytics Services use Analytics Information to compile reports on user
                activity. The Analytics Services may also transfer information to third parties where required to do so by
                law, or where such third parties process Analytics Information on their behalf. Each Analytics Services’
                ability to use and share Analytics Information is restricted by such Analytics Services’ Terms of Use and
                Privacy Policy. By using our website, you consent to the processing of data about you by Analytics Services
                in the manner and for the purposes set out above. For a full list of Analytics Services, please contact us
                at <a href="mailto:martin@storpartners.com">martin@storpartners.com </a>.</p>
            <h2>PROCESSING AND USE OF YOUR PERSONAL INFORMATION</h2>
            <p>We only collect and use your personal information when we have a legitimate reason for doing so. In this
                instance, we only collect personal information that is reasonably necessary to provide you access to our
                Platform and our services to you.</p>
            <p>Most commonly, we will use your personal data in the following circumstances:</p>
            <ul>
                <li><b>performance of a contract:</b> means processing your data where it is necessary for the performance
                    of a
                    contract to which you are a party or to take steps at your request before entering into such a
                    contract; we use this basis for the provision of our services offered on the Platform;</li>
                <li><b>legitimate interests:</b> means our interests (or those of a third party), where we make sure we use
                    this basis as far as your interests and individual rights do not override those interests;</li>
                <li><b>consent:</b> means freely given, specific, informed, and unambiguous indication of your wishes by
                    which you, by a statement or by a clear affirmative action, signify agreement to the processing of
                    personal data relating to you; under specific circumstances this consent should be explicit – if this is
                    the case, we will ask for it properly.</li>

            </ul>
            <p>We may collect, hold, use, and disclose information for the following purposes, and personal information will
                not be further processed in a manner that is incompatible with these purposes:</p>
            <ul>
                <li>to enable you to access and use our Platform, associated applications, and associated social media
                    platforms;</li>
                <li>to provide you with our Platform's core features and services;</li>
                <li>to facilitate with payment and transaction on the Platform;</li>
                <li>to enable you to customize or personalize your experience of our Platform;</li>
                <li>to contact and communicate with you, including promotional communications;</li>
                <li>to monitor and analyze trends, usage and activities for marketing and analytics purposes;</li>
                <li>to comply with our legal obligations and resolve any disputes that we may have;</li>
                <li>to detect, prevent, or investigate security breaches, fraud and other unauthorized or illegal activity;
                </li>
                <li>to enforce our Terms of Use;</li>
                <li>to fulfill a purpose with your consent.</li>

            </ul>
            <p>We may combine voluntarily provided and automatically collected personal information with general information
                or research data we receive from other trusted sources. For example, our marketing and market research
                activities may uncover data and insights, which we may combine with information about how visitors use our
                site to improve our site and your experience on it.</p>
            <p>Information you post publicly on our Platform may be accessed, collected, or used by others and it may result
                in unsolicited messages or contact from others. We strongly encourage you to use caution and discretion when
                sharing information publicly. </p>
            <h2>SECURITY OF YOUR PERSONAL INFORMATION</h2>
            <p>When we collect and process personal information, and while we retain this information, we will protect it
                within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure,
                copying, use, or modification. Although we will do our best to protect the personal information you provide
                to us, we advise that no method of electronic transmission or storage is 100% secure, and no one can
                guarantee absolute data security. You are responsible for selecting any password and its overall security
                strength, ensuring the security of your own information within the bounds of our Platform. For example,
                ensuring any passwords associated with accessing your personal information and accounts are secure and
                confidential.</p>
            <p>While there is an inherent risk in any data being shared over the internet, we have put in place appropriate
                security measures to prevent your personal data from being accidentally lost, used, damaged, or accessed in
                an unauthorized or unlawful way, altered, or disclosed. In addition, we limit access to your personal data
                to those employees, agents, contractors and other third parties who have a legitimate business need to know.
                They will only process your personal data on our instructions, and they are subject to a duty of
                confidentiality.</p>
            <p>Depending on the nature of the risks presented by the proposed processing of your personal data, we will have
                in place the following appropriate security measures:</p>
            <ul>
                <li><b>organizational measures</b> (including but not limited to staff training and policy development);
                </li>
                <li><b>technical measures</b> (including but not limited to physical protection of data, pseudonymization
                    and encryption);</li>
                <li><b>securing ongoing availability, integrity, and accessibility</b> (including but not limited to
                    ensuring appropriate back-ups of personal data are held).</li>
            </ul>
            <p>We have put in place procedures to deal with any suspected personal data breach and will notify you and any
                relevant regulator of a breach where we are legally required to do so.</p>
            <h2>HOW LONG WE KEEP YOUR PERSONAL INFORMATION</h2>
            <p>We keep your personal information only for as long as we need to. This time period may depend on what we are
                using your information for, in accordance with this Privacy Policy. If your personal information is no
                longer required for this purpose, we will delete it or make it anonymous by removing all details that
                identify you.</p>
            <p>To determine the appropriate retention period for personal data, we consider the amount, nature and
                sensitivity of the personal data, the potential risk of harm from unauthorized use or disclosure of your
                personal data, the purposes for which we process your personal data and whether we can achieve those
                purposes through other means, and the applicable legal, regulatory, tax, accounting, or other requirements.
            </p>
            <p>Here are some exemplary factors which we usually consider when determining how long we need to retain your
                personal data:</p>
            <ul>
                <li>in the event of a complaint;</li>
                <li>if we reasonably believe there is a prospect of litigation in respect to our relationship with you or if
                    we consider that we need to keep information to defend possible future legal claims;</li>
                <li>to comply with any applicable legal and/or regulatory requirements with respect to certain types of
                    personal data, if information is needed for audit purposes and so forth;</li>
                <li>in accordance with relevant industry standards or guidelines;</li>
                <li>in accordance with our legitimate business need to prevent abuse of the promotions that we launch. We
                    will retain a customer’s personal data for the time of the promotion and for a certain period after its
                    end to prevent the appearance of abusive behavior.</li>

            </ul>
            <p>Please note that under certain condition(s), you can ask us to delete your data in accordance with this
                Privacy Policy. </p>
            <h2>DISCLOSURE OF PERSONAL INFORMATION TO THIRD PARTIES</h2>
            <p>We share your personal data with our third-party service providers, agents, subcontractors and other
                associated organizations, our group companies, and affiliates (as described below) in order to complete
                tasks and provide the Platform and services to you on our behalf. When using third party service providers,
                they are required to respect the security of your personal data and to treat it in accordance with the law.
            </p>
            <p className='mb-0'>We may disclose personal information to:</p>
            <ul style={{ marginTop: "5px" }}>
                <li>a parent, subsidiary, or affiliate of the Company</li>
                <li>third-party service providers for the purpose of enabling them to provide their services, including
                    (without limitation) IT service providers, data storage, hosting and server providers, analytics, error
                    loggers, debt collectors, maintenance or problem-solving providers, professional advisors, and payment
                    systems operators</li>
                <li>our employees, contractors, and/or related entities</li>
                <li>our existing or potential agents or business partners</li>
                <li>credit reporting agencies, courts, tribunals, and regulatory authorities, in the event you fail to pay
                    for goods or services we have provided to you</li>
                <li>courts, tribunals, regulatory authorities, and law enforcement officers, as required by law, in
                    connection with any actual or prospective legal proceedings, or in order to establish, exercise, or
                    defend our legal rights</li>
                <li>third parties, including agents or sub-contractors, who assist us in providing information, products,
                    services, or direct marketing to you</li>
                <li>third parties to collect and process data</li>
                <li>an entity that buys, or to which we transfer all or substantially all of our assets and business</li>
            </ul>
            <h2>YOUR RIGHTS AND CONTROLLING YOUR PERSONAL INFORMATION</h2>
            <p><b>Your choice:</b> By providing personal information to us, you understand we will collect, hold, use, and
                disclose your personal information in accordance with this Privacy Policy. You may choose to reject cookies
                and certain other tracking technologies by changing the settings of your browser. You do not have to provide
                personal information to us, however, if you do not, it may affect your use of our Platform or the products
                and/or services offered on or through it.</p>
            <p><b>Information from third parties:</b> If we receive personal information about you from a third party, we
                will protect it as set out in this Privacy Policy. If you are a third party providing personal information
                about somebody else, you represent and warrant that you have such person’s consent to provide the personal
                information to us.</p>
            <p><b>Marketing permission:</b> If you have previously agreed to us using your personal information for direct
                marketing purposes, you may change your mind at any time by opting out of the promotional communications or
                contacting us using the details below.</p>
            <p><b>Access:</b> You may request details of the personal information that we hold about you.</p>
            <p><b>Correction:</b> If you believe that any information we hold about you is inaccurate, out of date,
                incomplete, irrelevant, or misleading, please contact us using the details provided in this privacy policy.
                We will take reasonable steps to correct any information found to be inaccurate, incomplete, misleading, or
                out of date.</p>
            <p><b>Non-discrimination:</b> We will not discriminate against you for exercising any of your rights over your
                personal information. Unless your personal information is required to provide you with a particular service
                or offer (for example providing user support), we will not deny you goods or services and/or charge you
                different prices or rates for goods or services, including through granting discounts or other benefits, or
                imposing penalties, or provide you with a different level or quality of goods or services.</p>
            <p><b>Notification of data breaches:</b> We will comply with laws applicable to us in respect of any data
                breach.</p>
            <p><b>Complaints:</b> If you believe that we have breached a relevant data protection law and wish to make a
                complaint, please contact us using the details below and provide us with full details of the alleged breach.
                We will promptly investigate your complaint and respond to you, in writing, setting out the outcome of our
                investigation and the steps we will take to deal with your complaint. You also have the right to contact a
                regulatory body or data protection authority in relation to your complaint.
            </p>
            <p><b>Unsubscribe:</b> To unsubscribe from our email database or opt-out of communications (including marketing
                communications), please contact us using the details provided in this privacy policy or opt-out using the
                opt-out facilities provided in the communication. We may need to request specific information from you to
                help us confirm your identity.</p>
            <p><b>No fee usually required:</b> You will not have to pay a fee to access your personal data (or to exercise
                any of the other rights). However, we may charge a reasonable fee if your request is manifestly unfounded or
                excessive. Alternatively, we could refuse to comply with your request in these circumstances. We may need to
                request specific information from you to help us confirm your identity and ensure your right to access your
                personal data (or to exercise any of your other rights). This is a security measure to ensure that personal
                data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for
                further information in relation to your request to speed up our response.</p>
            <p><b>Period for replying to a legitimate request:</b> We try to respond to all legitimate requests within one
                month. Occasionally it may take us longer than a month if your request is particularly complex or you have
                made a number of requests. In this case, we will notify you and keep you updated.</p>
            <p>If you are a user in the European Economic Area (“EEA”), United Kingdom (“UK”) or Switzerland, you have
                certain rights under applicable law. These include the right to (i) request access and obtain a copy of your
                personal information; (ii) request rectification or erasure of your personal information; (iii) object to or
                restrict the processing of your personal information; and (iv) request portability of your personal
                information. Additionally, if we have collected and processed your personal information with your consent,
                you have the right to withdraw your consent at any time.</p>
            <p>If you are a California resident, you have certain rights under the California Privacy Rights Act ("CPRA").
                These include the right to (i) request access to, details regarding, and a copy of the personal information
                we have collected about you and/or shared with third parties; (ii) request deletion of the personal
                information that we have collected about you; and (iii) the right to opt-out of the sale of your personal
                information. As the terms are defined under the CPRA, we do not “sell” your “personal information.”</p>
            <p>Notwithstanding the above, we cannot edit or delete any information that is stored on a blockchain, such as
                the Ethereum Blockchain, as we do not have custody or control over any blockchains. The information stored
                on the blockchain may include purchases, sales, and transfers related to your blockchain address and tokens
                held at that address.</p>
            <h2>CHILDREN’S PRIVACY</h2>
            <p>We do not intentionally gather information from visitors who are under the age of 13. Our Terms of Use
                require all users to be at least 18 years old. Minors who are at least 13 years old but are under 18 years
                old may use a parent or guardian’s account, but only with the supervision of the account holder. If a child
                under 13 submits identifiable information to us and we learn that the identifiable information is the
                information of a child under 13, we will attempt to delete the information as soon as possible. If you
                believe that we might have any identifiable information from a child under 13, please contact us at the
                following address: <a href="mailto:martin@storpartners.com">martin@storpartners.com </a>.</p>
            <h2>BUSINESS TRANSFERS</h2>
            <p>If we or our assets are acquired, or in the unlikely event that we go out of business or enter bankruptcy, we
                would include data, including your personal information, among the assets transferred to any parties who
                acquire us. You acknowledge that such transfers may occur, and that any parties who acquire us may, to the
                extent permitted by applicable law, continue to use your personal information according to this policy,
                which they will be required to assume as it is the basis for any ownership or use rights we have over such
                information.</p>
            <h2>INTERNATIONAL TRANSFERS</h2>
            <p>All information processed by us may be transferred, processed, and stored anywhere in the world, including,
                but not limited to, the United States, members of the European Union, or other countries, which may have
                data protection laws that are different from the laws where you live. We endeavor to safeguard your
                information consistent with the requirements of applicable laws.</p>
            <h2>LIMITS OF OUR POLICY</h2>
            <p>Our Platform may link to external sites that are not operated by us. Please be aware that we have no control
                over the content and policies of those sites and cannot accept responsibility or liability for their
                respective privacy practices.</p>
            <p>To use certain aspects of our Platform, you must use a third-party wallet that allows you to engage in
                transactions on public blockchains. Your interactions with any third-party wallet provider are governed by
                the applicable terms of service and privacy policy of that third party.</p>
            <h2>CHANGES TO THIS POLICY</h2>
            <p>At our discretion, we may change our privacy policy to reflect updates to our business processes, current
                acceptable practices, or legislative or regulatory changes. If we decide to change this privacy policy, we
                will post the changes here at the same link by which you are accessing this privacy policy.</p>
            <p>If the changes are significant, or if required by applicable law, we will contact you (based on your selected
                preferences for communications from us) and all our registered users with the new details and links to the
                updated or changed policy.</p>
            <p>If required by law, we will get your permission or give you the opportunity to opt in to or opt out of, as
                applicable, any new uses of your personal information.</p>
            <h2>CONTACT US FOR QUESTIONS AND REPORTING VIOLATIONS</h2>
            <p>If you have any questions, concerns, or complaints about our Privacy Policy or our data collection and
                processing practices, or if you want to report any security violations to us, or exercise any of your rights
                under this Privacy Plolicy, please contact us at the following address: <a
                    href="mailto:martin@storpartners.com">martin@storpartners.com </a>.</p>

        </div>
    )
}

export default PrivacyPolicy