import './App.scss';
import ContextProvider from './AppContext';
import { HashRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import FAQ from './pages/FAQ';
import ContactUs from './pages/ContactUs';
import Layout from './layout/Layout';
import HomeS from './pages/HomeS';
import HomeT from './pages/HomeT';
import PrivacyPolicy from './pages/PrivacyPolicy';
import PrivacyPolicyT from './pages/PrivacyPolicyT';
import PrivacyPolicyS from './pages/PrivacyPolicyS';
import TermsConditions from './pages/TermsConditions';
import Disclaimer from './pages/Disclaimer';
import ThankYou from './components/ThankYou';
import ContactThankYou from './components/contactThankYou';

function App() {
  return (
    <ContextProvider>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            {/* / routes */}
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/faq' element={<FAQ />} />
            <Route path='/contact-us' element={<ContactUs />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/term-and-condition' element={<TermsConditions />} />
            <Route path='/disclaimer' element={<Disclaimer />} />
            <Route path='/contact-thank-you' element={<ContactThankYou />} />
            <Route path='/pdf1-thank-you' element={<ThankYou />} />
            {/* homeS routes */}
            <Route path='/homeS' element={<HomeS />} />
            <Route path='/homeS/about' element={<About />} />
            <Route path='/homeS/faq' element={<FAQ />} />
            <Route path='/homeS/contact-us' element={<ContactUs />} />
            <Route path='/homeS/privacy-policyS' element={<PrivacyPolicyS />} />
            <Route path='/homeS/term-and-condition' element={<TermsConditions />} />
            <Route path='/homeS/disclaimer' element={<Disclaimer />} />
            <Route path='/homeS/contact-thank-you' element={<ContactThankYou />} />
            <Route path='/homeS/pdf2-thank-you' element={<ThankYou />} />
            {/* homeT routes */}
            <Route path='/homeT' element={<HomeT />} />
            <Route path='/homeT/about' element={<About />} />
            <Route path='/homeT/faq' element={<FAQ />} />
            <Route path='/homeT/contact-us' element={<ContactUs />} />
            <Route path='/homeT/privacy-policyT' element={<PrivacyPolicyT />} />
            <Route path='/homeT/term-and-condition' element={<TermsConditions />} />
            <Route path='/homeT/disclaimer' element={<Disclaimer />} />
            <Route path='/homeT/contact-thank-you' element={<ContactThankYou />} />
            <Route path='/homeT/pdf3-thank-you' element={<ThankYou />} />
          </Route>
        </Routes>
      </HashRouter>
    </ContextProvider>
  );
}

export default App;
