import React, { useEffect, useState } from 'react'
import Logo from "../assets/images/footerLogo.svg";
import ScrollToTopLink from './ScrollToTopLink';
import { useLocation } from 'react-router-dom';
const Footer = () => {
    const location = useLocation();
    const [getPath, setPath] = useState();
    const [getPrivacy, setPrivacy] = useState();

    useEffect(() => {
        if (location.pathname.includes('homeS')) {
            setPath('homeS/');
            setPrivacy("privacy-policyS")
        } else if (location.pathname.includes("homeT")) {
            setPath("homeT/");
            setPrivacy("privacy-policyT")
        } else {
            setPath("/");
            setPrivacy("privacy-policy")
        }
    }, [location.pathname]);
    return (
        <footer>
            <div className="cusContainer">
                <div className="footer-top">
                    <div className="col-left">
                        <div className="footer_logo">
                            <ScrollToTopLink to={getPath}>
                                <img src={Logo} alt=' logo' />
                            </ScrollToTopLink>
                        </div>
                    </div>
                    <div className="col-right flex">
                        <div className="footer-widget">
                            <div className="widget-title">
                                General

                            </div>
                            <ul className='widget-about'>
                                <li>
                                    <ScrollToTopLink to={`${getPath}about`}>About</ScrollToTopLink>
                                </li>
                                <li>
                                    <ScrollToTopLink to={`${getPath}faq`}>FAQ</ScrollToTopLink>
                                </li>
                                <li>
                                    <ScrollToTopLink to={`${getPath}contact-us`}>Contact Us</ScrollToTopLink>
                                </li>
                                <li>
                                    <a target='_blank' href="http://storpartners.com/Blog/">Blog</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom flex_jus_item">
                    <div className="col-left">
                        <ul className='mb-0'>
                            <li>
                                <ScrollToTopLink to={`${getPath}${getPrivacy}`}>Privacy Policy</ScrollToTopLink>
                            </li>
                            <li>
                                <ScrollToTopLink to={`${getPath}term-and-condition`}>Terms & Conditions</ScrollToTopLink>
                            </li>
                            <li>
                                <ScrollToTopLink to={`${getPath}disclaimer`}>Disclaimer</ScrollToTopLink>
                            </li>
                        </ul>
                    </div>
                    <div className="col-right">
                        © STOR All rights reserved 2023
                    </div>
                </div>
            </div>

        </footer>
    )
}

export default Footer