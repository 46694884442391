import React, { useContext, useEffect, useState } from 'react'
import btnArrow from "../assets/images/btnArrow.svg";
import ContactStore from "../assets/images/contactStore.png";
import { Controller, useForm } from 'react-hook-form';
import { handlePhoneNumberKeyPress } from '../helper';
import { Modal } from 'react-bootstrap';
import { axiosInstance } from '../http/axios';
import { AppContext } from '../AppContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const ContactUs = () => {
    const { setLoader } = useContext(AppContext);
    const [show, setShow] = useState(false);
    const [countryCode, setCountryCode] = useState();
    const handleClose = () => setShow(false);
    const { pathname } = useLocation()
    const { handleSubmit, register, control, formState: { errors }, reset } = useForm();
    const navigate = useNavigate();
    const handleContactForm = (data) => {
        setLoader(true)
        console.log("asdkfj", data);
        let type = 1;
        if (data?.subject_message === "Question/Request") {
            type = 2
        } else if (data?.subject_message === "Get Your Free Guide Now") {
            type = 3
        }
        const objData = data;
        const inputString = data?.name;
        const [firstname, lastname] = inputString.split(/\s(.+)/);
        const bodyData = {
            ...data,
            firstname: firstname,
            lastname: lastname,
            type,
            ...(data?.newsletter ? { newsletter: 1 } : { newsletter: 0 }),
        };
        let path;
        if (pathname.includes('homeS')) {
            path = '/homeS/contact-thank-you'
        } else if (pathname.includes("homeT")) {
            path = "/homeT/contact-thank-you"
        } else {
            path = "/contact-thank-you"
        }
        try {
            axiosInstance
                .post(`/contact-submit`, bodyData)
                .then((res) => {
                    for (const key in objData) {
                        objData[key] = ""
                    }
                    reset(objData);
                    if (res.status === 200) {
                        navigate(path)
                    }
                    setLoader(false);
                });
        } catch (error) {
            console.log("error", error);
            setLoader(false);
        }
    }

    // get counter code
    useEffect(() => {
        try {
            axiosInstance.get("/country-list").then((res) => {
                let countryCodelist = res?.data?.country_list;
                const countryC = countryCodelist?.map(country => country?.phonecode).filter(a => a !== "0")
                const uniqueArray = [...new Set(countryC)];
                setCountryCode(uniqueArray)
                console.log("uniqueArray", uniqueArray, countryCodelist)
            })
        } catch (error) {
            console.log("error", error)
        }
    }, [])

    return (
        <>
            <main className='innerMain'>
                <h2 className='head2 text-white'>Contact Us</h2>
            </main>
            <section className="contactus afRoundImg">
                <div className="cusContainer ps-md-0">
                    <div className="row">
                        <div className="col-lg-5 col-md-4">
                            <img src={ContactStore} alt="contactStore" className="w-100 contactStore" />
                        </div>
                        <div className="col-lg-7  col-md-8">
                            <div className="contact_form">
                                <form onSubmit={handleSubmit(handleContactForm)}>
                                    <div className="inputGroup mb-sm-3 mb-2">
                                        <label>Name<sup>*</sup></label>
                                        <input type="text" {...register("name", { required: true })} className='inputControl' />
                                        {errors.name && <p className='error ms-0'>Name is required</p>}
                                    </div>
                                    <div className="inputGroup mb-sm-3 mb-2">
                                        <label>Email Address<sup>*</sup></label>
                                        <input type="email" {...register("email", { required: true })} className='inputControl' />
                                        {errors.email && <p className='error ms-0'>Email is required</p>}
                                    </div>
                                    <div className="inputGroup mb-sm-3 mb-2">
                                        <label>Phone Number<sup>*</sup></label>
                                        <div className="addNum">
                                            <div className="afLin">
                                                <select className='addnumSelect' {...register("country_code")}>
                                                    {countryCode?.map((countryCode, index) => <option key={countryCode + index}>{"+ " + countryCode}</option>)}
                                                </select>
                                            </div>
                                            <Controller
                                                name="phone"
                                                control={control}
                                                rules={{
                                                    required: true,
                                                    pattern: {
                                                        value: /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
                                                        message: 'Invalid phone number format',
                                                    }
                                                }}
                                                render={({ field }) => (
                                                    <input {...field} type="text" maxLength={10} className='addinput' onKeyDown={handlePhoneNumberKeyPress} />
                                                )}
                                            />
                                        </div>
                                        {errors.phone && <p className='error ms-0'>Phone number is required</p>}
                                        {errors.countryCode && <p className='error ms-0'>Country Code is required</p>}
                                        {errors.phone && <p className='error ms-0'>{errors.phone.message}</p>}
                                    </div>
                                    <div className="inputGroup mb-sm-3 mb-2 mt-md-4">
                                        <label>What’s the subject of your message</label>
                                        <select className='inputControl' {...register("subject_message")}>
                                            <option value="Phone Call with Dr. Smolarz">Phone Call with Dr. Smolarz</option>
                                            <option value="Question/Request">Question/Request</option>
                                            <option value="Get Your Free Guide Now">Get Your Free Guide Now</option>

                                        </select>
                                        {errors.subject_message && <p className='error ms-0'>Subject is required</p>}
                                    </div>
                                    <div className="inputGroup mb-sm-3 mb-2">
                                        <label>Your Message</label>
                                        <textarea className='textarea' {...register("message")}></textarea>
                                        {errors.message && <p className='error ms-0'>Message is required</p>}
                                    </div>
                                    <div className="inputGroup mb-sm-3 mb-2">
                                        <div className="d-flex align-items-start">
                                            <input type="checkbox" id='checkbox' className='me-2 mt-1' {...register("termAndCond", { required: true })} />
                                            <label htmlFor='checkbox'>I agree to the <Link className='text-primary' to={"/term-and-condition"}>Terms & Conditions</Link>  provided by the company. By providing my phone number, I agree to receive text messages from the business.</label>
                                        </div>
                                        {errors.termAndCond && <p className='error ms-0'>This field is required</p>}
                                    </div>
                                    <div className="inputGroup mb-sm-3 mb-2">
                                        <div className="d-flex">
                                            <input type="checkbox" id='checkbox' className='me-2' {...register("newsletter")} />
                                            <label htmlFor='checkbox'>Want to get our newsletters?</label>
                                        </div>
                                    </div>
                                    <button className="btnC mt-sm-4 mt-2" type='submit'>
                                        <div className="d-flex" >
                                            Send Message
                                            <img src={btnArrow} alt="icon" className="BtnImgFile ms-1" />
                                        </div></button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* modal here */}
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
                centered
            >

                <div className='modalBoay'>
                    <h2 className="head2 mb-4">Thank you for Reaching Out!</h2>

                    <h5 className="head5">👨‍⚕️ Hello and Welcome!</h5>
                    <p className='txt'>
                        We're delighted that you've taken the time to contact us. Whether you're seeking
                        more information or interested in a personal discussion with Dr. Smolarz, you've
                        come to the right place.
                    </p>
                    <h5 className='head5'>📨 We've Received Your Inquiry</h5>
                    <p className='txt'>Your message is important to us, and our team is currently reviewing your
                        submission. We will get back to you as soon as possible to address your
                        questions or set up a call, as per your preference.</p>

                    <h5 className='head5'>📞 A Note from Dr. Smolarz</h5>
                    <p className='txt'>"I understand the unique financial challenges that medical professionals face.
                        I look forward to the possibility of discussing these issues with you and
                        helping you navigate towards financial freedom."</p>


                    <h5 className='head5'>👋 We're Here to Help</h5>
                    <p className='txt'>Thank you for trusting us with your financial concerns. We're committed to
                        providing you the guidance you need to achieve your goals.
                    </p>

                    <div className="modalBoayFtr mt-5 text-center">
                        <button className='btnC' onClick={handleClose}>OK</button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ContactUs