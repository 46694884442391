import React from 'react'
import { Modal } from 'react-bootstrap'
import ScrollToTopLink from './ScrollToTopLink'

const CommonModal = ({ show, handleClose, title }) => {
    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="lg"
            centered
        >

            <div className='modalBoay'>
                <h2 className="head2 mb-4">Thank you for Downloading!</h2>

                <h5 className="head5">👨‍⚕️ Hello Doctor!</h5>
                <p className='txt'>
                    We're thrilled that you've taken the first step towards achieving your
                    financial goals and breaking those golden handcuffs.
                </p>
                <h5 className='head5'>📚 Your Free Guide: "{title}" is on Its Way!</h5>
                <p className='txt'>Please check your email to download your copy. If you don't see it,
                    don't forget to check your spam or promotions folder.</p>
                <h5 className='head5'>📞 A Personal Invitation from Dr. Smolarz</h5>
                <p className='txt'>Dr. Smolarz, the financial mentor for doctors like you, would be
                    honored to speak with you directly to discuss your unique financial
                    challenges and potential solutions. Schedule your personal call now
                    to take control of your financial future.</p>
                <h5 className='head5 mb-3'>👉 <ScrollToTopLink to="contact-us">Schedule a Call with Dr. Smolarz</ScrollToTopLink></h5>
                <h5 className='head5'>🤔 Questions?</h5>
                <p className='txt'>Our team is always here to help. Feel free to reach out to us at <ScrollToTopLink to="contact-us" className='linkM'>CONTACT US.</ScrollToTopLink>
                </p>
                <p className='txt'>🙏 Thank you for trusting us to be a part of your financial journey.
                    Here's to your success!</p>
                <div className="modalBoayFtr mt-5 text-center">
                    <button className='btnC' onClick={handleClose}>OK</button>
                </div>
            </div>
        </Modal>
    )
}

export default CommonModal