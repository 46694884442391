import React from 'react'

const Disclaimer = () => {
    return (
        <>
            <div class="container">
                <h1 style={{ textAlign: "center", marginBottom: "20px" }}>Disclaimer</h1>
                <h2>General Information</h2>
                <p>The information contained in this website is for general information purposes only. The information is provided by Joseph Ryan Smolarz, MD, MBA of STOR Partners. We make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability concerning the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.
                </p>
                <h2>Investment Risks</h2>
                <p>The content of this website does not constitute investment advice and should not be relied upon in making (or refraining from making) any decision. Investments in real estate are speculative and involve substantial risks, including the risk of loss. There is no assurance that any investment strategy will succeed.</p>
                <h2>No Offer or Solicitation</h2>
                <p>The information provided on this website does not constitute an offer or solicitation to sell shares or securities.
                </p>
                <h2>Regulatory Oversight</h2>
                <p>STOR Partners is not registered as an investment advisor, broker/dealer, or other financial services provider with any federal or state regulatory agency. We are not licensed nor qualified to provide investment advice.
                </p>
                <h2>External Links</h2>
                <p>Through this website, you may be able to link to other websites that are not under the control of STOR Partners. We have no control over the nature, content, and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorsement of the views expressed within them.</p>
                <h2>Governing Law</h2>
                <p>This Disclaimer is governed by and construed in accordance with the laws of Delaware and Wyoming.</p>
                <h2>Changes to this Disclaimer</h2>
                <p>We reserve the right to modify this Disclaimer at any time. Your continued use of this website after changes are posted constitutes your acceptance of the amended terms.</p>
                <p>For further information, please contact:</p>
                <p><b>Attn: Joseph Ryan Smolarz</b></p>
                <p><b>STOR Token Holdings LLC</b></p>
                <address>
                    <b><a href="tel:+340514-0344" className='text-decoration-none'>(340)514-0344</a></b><br />
                    Address: 1309 Coffeen Avenue STE 1200 <br />
                    Sheridan, Wyoming 82801 <br />
                    <b>Email:  <a href="mailto:rsmolarz@rsmolarz.com" className='text-decoration-none'>rsmolarz@rsmolarz.com</a></b>
                </address>
            </div>
        </>
    )
}

export default Disclaimer