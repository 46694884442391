export const handlePhoneNumberKeyPress = (event) => {
    // Allow only numeric characters, backspace, and delete key
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Delete'];

    if (!allowedKeys.includes(event.key)) {
        event.preventDefault();
    }
};

export function encodeSpacesInUrl(url) {
    return url.replace(/ /g, '%20');
}