import { createContext, useState } from "react";
import Loader from "./components/Loader";

export const AppContext = createContext();

const ContextProvider = (props) => {

    const [value, setValue] = useState();
    const [loader, setLoader] = useState(false);
    const [title, setTitle] = useState()
    return (
        <>
            {loader && <Loader />}
            <AppContext.Provider
                value={{
                    value,
                    setValue,
                    loader,
                    setLoader,
                    title,
                    setTitle
                }}
            >
                {props.children}
            </AppContext.Provider>
        </>
    )
}

export default ContextProvider;