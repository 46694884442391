import React, { useEffect, useState } from 'react'
import ScrollToTopLink from './ScrollToTopLink'
import { useLocation } from 'react-router-dom';

const JoinJ = () => {

    const { pathname } = useLocation();
    const [getPath, setPath] = useState();

    useEffect(() => {
        if (pathname.includes('homeS')) {
            setPath('homeS/');
        } else if (pathname.includes("homeT")) {
            setPath("homeT/")
        } else {
            setPath("/")
        }
    }, [pathname]);

    return (
        <section className="jjf_in">
            <div className="cusContainer">
                <div className="jjf_inMax">
                    <h2 className='head2 text-white'>
                        NAVIGATE YOUR FINANCIAL FUTURE WITH US
                    </h2>
                    <p className='txt text-white'> As a peer in the medical field, I recognize the distinct financial hurdles we might encounter. That’s why the STOR Self-Storage Fund has been curated with insights into the potential needs and preferences of physicians like us. Consider joining this exploration toward financial navigation, tailored with our specific situations in mind.</p>
                    <p className='txt text-white'>Keen to explore how you might navigate through financial landscapes? Click below to request a personal call with me, Dr. Smolarz, and begin exploring your financial pathways today.</p>

                    <ScrollToTopLink to={`${getPath}contact-us`} className="btnC mt-sm-4 mt-3 d-inline-block">Request a Call</ScrollToTopLink>
                </div>
            </div>
        </section>
    )
}

export default JoinJ