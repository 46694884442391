import React from 'react'
import MainRoundC from "../assets/images/mainRoundC.svg";
import Profile from "../assets/images/profile.png";
import ProfileS from "../assets/images/martin.png";
const About = () => {
    return (
        <>
            <main className='innerMain'>
                <div className="mainBoxDr">
                    <img src={MainRoundC} alt="" className='me-2' />
                    Meet the Awesome Team
                </div>
                <h2 className='head2 text-white'>About us</h2>
            </main>
            <section className='aboutSF afRoundImg'>
                <div className="cusContainer">
                    <div className="whiteBox">
                        <div className="aboutInfo">
                            <h2 className="head2 mb-0"><span>Dr. Joseph Ryan Smolarz </span> <span className='headspan'>(Founder & CEO)</span></h2>
                            <div className="profile_info mt-sm-3">
                                A Visionary Leader
                            </div>
                            <h3 className='head3 mt30'>Introduction</h3>
                            <p className='txt mb-0'>Dr. Joseph Ryan Smolarz is the founder of STOR, leveraging his vast experience as an entrepreneur and Otolaryngology practitioner to guide people toward financial sovereignty. He has developed patents in the medical field and has broad experience in commercial real estate, including self-storage and assisted living.</p>
                            <div className="profileV">
                                <img src={Profile} alt="profile" className='profile' />
                            </div>
                        </div>
                        <div className="mt-4">
                            <div className="grid2">
                                <div className="whtboxBn">
                                    <h2 className="head2">Accomplishments</h2>
                                    <ul className='mb-0 mt-3'>
                                        <li>
                                            <p className='txt'>Medical Innovations: Developed a reflux medication patent and another invention that is patent-pending.</p>
                                        </li>
                                        <li>
                                            <p className='txt'>  Real Estate Expertise: Years of development and acquisitions experience in self-storage and assisted living.</p>
                                        </li>
                                        <li>
                                            <p className='txt'> Team Leadership: Emphasizes teamwork and detail in team-building, aided by his MBA from the University of Miami.</p>
                                        </li>
                                    </ul>



                                </div>
                                <div className="whtboxBn">
                                    <h2 className="head2">Education</h2>
                                    <ul className='mb-0 mt-3'>
                                        <li>
                                            <p className='txt'> University of Miami - Executive MBA, Business</p>
                                        </li>
                                        <li>
                                            <p className='txt'>  The University of Texas Health Science Center at Houston - Residency, Otolaryngology</p>
                                        </li>
                                        <li>
                                            <p className='txt'> The University of Texas Medical Branch - Medical Doctor, Medicine</p>
                                        </li>
                                        <li>
                                            <p className='txt'> The University of Texas at Austin - BA, Microbiology</p>
                                        </li>
                                    </ul>




                                </div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <div className="whtboxBn">
                                <div className="redTxt">Personal Life</div>
                                <p className='txt mb-0'>Proud father and husband, Dr. Smolarz values family and is passionate about new technologies.</p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4">
                        <div className="whiteBox">
                            <div className="aboutInfo bimcolor">
                                <h2 className="head2 mb-0"> <span>Martin Taradejna</span><span className='headspan'>(Chief Marketing Officer)</span></h2>
                                {/* 
                                <div className="profile_info mt-sm-3">
                                    A Strategic Mastermind
                                </div> */}
                                <h3 className='head3 mt30'>Introduction</h3>
                                <p className='txt mb-0'>
                                    Martin began his real estate career in 2010 and is a seasoned self storage industry professional. Martin's previous involvement with historical tax credit, opportunity zone, and Pace financed projects make him a valuable asset to the STOR team.
                                    <br />
                                    <br />
                                    Previous Experience
                                    <br />
                                    Martin has:
                                    <br />
                                    -&nbsp;Contributed to the acquisitions, conversions, and operations of over $50MM in self storage assets exceeding over 4,000 units and nearly 500,000 square feet
                                    <br />
                                    -&nbsp;Developed strong investor relations with transparency to provide confidence for his equity partners
                                </p>
                                <div className="profileV">
                                    <img src={ProfileS} alt="profile" className='profile' />
                                </div>
                            </div>
                            <div className="mt-4">
                                <div className="grid2">
                                    <div className="whtboxBn">
                                        <h2 className="head2">Professional Experience</h2>
                                        <ul className='mb-0 mt-3'>
                                            <li>
                                                <p className='txt'>  Acquisitions Expertise: Acquired, converted, and operated over $50MM in self-storage.</p>
                                            </li>
                                            <li>
                                                <p className='txt'>  Portfolio Management: Managed over 4,000 units and nearly half a million square feet across the U.S.</p>
                                            </li>
                                            <li>
                                                <p className='txt'> Investor Relations: Skills in structuring deals to maximize returns, coupled with strong communication and trust-building with stakeholders.</p>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About