import React from 'react'
import { Controller } from 'react-hook-form'
import { Link } from 'react-router-dom';

const Form = ({ handleSubmit2, handleGetYourFreeGuide, register2, errors2, BtnImgFile, control, handlePhoneNumberKeyPress }) => {
    console.log("asdfasdfdsf", errors2);
    return (
        <form onSubmit={handleSubmit2(handleGetYourFreeGuide)}>
            <div className="main_input">

                <div className="inputGroup  mt-3">
                    <div className="inputItems">
                        <input {...register2("name", { required: true })} className='fillIn px-4 requiredStar' placeholder='Name' />
                        {errors2.name && <p className='error'>Name is required</p>}
                    </div>
                    <div className="inputItems  mt-3">
                        <input type="email" {...register2("email", { required: true })} className='fillIn px-4 requiredStar' placeholder='Email' />
                        {errors2.email && <p className='error'>Email is required</p>}
                    </div>
                </div>
                <div className="inputItems mt-3">
                    {/* addNum */}
                    <div className="n">
                        <Controller
                            name="phoneNumber"
                            control={control}
                            rules={{
                                required: true,
                                pattern: {
                                    value: /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
                                    message: 'Invalid phone number format',
                                },
                            }}
                            render={({ field }) => (
                                <input {...field} type="text" maxLength={10} placeholder='Phone Number' className='fillIn px-4 requiredStar' onKeyDown={handlePhoneNumberKeyPress} />
                            )}
                        />
                    </div>
                </div>
                {errors2.phoneNumber && <p className='error'>Phone number is required.</p>}
                {errors2.phoneNumber && <p className='error'>{errors2.phoneNumber.message}</p>}
                <div className="inputGroup mb-sm-3 mb-2 mt-2">
                    <div className="d-flex align-items-start HtermCond">
                        <input type="checkbox" id='checkbox' className='me-2 mt-1' {...register2("termAndCond", { required: true })} />
                        <label htmlFor='checkbox'>I agree to the <Link className='text-primary' to={"/term-and-condition"}>Terms & Conditions</Link>  provided by the company. By providing my phone number, I agree to receive text messages from the business.</label>
                    </div>
                    {errors2.termAndCond && <p className='error ms-0'>This field is required</p>}
                </div>
            </div>
            <button className="btnC mt-4" type='submit'><div className="d-flex">

                <img src={BtnImgFile} alt="icon" className='BtnImgFile me-1' />
                Get Your Free Guide Now</div></button>
        </form>
    )
}

export default Form