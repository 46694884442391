import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const ContactThankYou = () => {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, [])
    return (
        <div className="thanku py-lg-5 py-3">
            <div className="cusContainer">
                <div className='modalBoay px-0'>
                    <h2 className="head2 mb-4">Thank you for Reaching Out!</h2>

                    <h5 className="head5">👨‍⚕️ Hello and Welcome!</h5>
                    <p className='txt'>
                        We're delighted that you've taken the time to contact us. Whether you're seeking
                        more information or interested in a personal discussion with Dr. Smolarz, you've
                        come to the right place.
                    </p>
                    <h5 className='head5'>📨 We've Received Your Inquiry</h5>
                    <p className='txt'>Your message is important to us, and our team is currently reviewing your
                        submission. We will get back to you as soon as possible to address your
                        questions or set up a call, as per your preference.</p>

                    <h5 className='head5'>📞 A Note from Dr. Smolarz</h5>
                    <p className='txt'>"I understand the unique financial challenges that medical professionals face.
                        I look forward to the possibility of discussing these issues with you and
                        helping you navigate towards financial freedom."</p>


                    <h5 className='head5'>👋 We're Here to Help</h5>
                    <p className='txt'>Thank you for trusting us with your financial concerns. We're committed to
                        providing you the guidance you need to achieve your goals.
                    </p>

                    <div className="modalBoayFtr mt-5 text-center">
                        <button className='btnC' onClick={() => navigate(-1)}>OK</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactThankYou