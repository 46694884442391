import { Link } from "react-router-dom";

const ScrollToTopLink = ({ to, children, className }) => {
  const handleClick = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  };

  return (
    <Link to={to} onClick={(e) => { handleClick() }
    } className={className}>
      {children}
    </Link >
  );
};

export default ScrollToTopLink;