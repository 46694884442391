import React from 'react'
import { Accordion } from 'react-bootstrap'
import BlueImgIn from "../assets/images/lightBlue_img.png";

const FAQ = () => {
    return (
        <>
            <main className='innerMain'>
                <h2 className='head2 text-white'>Frequently Asked Questions</h2>
            </main>
            <section className='faq afRoundImg'>
                <div className="cusContainer">
                    <h2 className="head2 mb30">Frequently Asked Questions</h2>
                    <div className="row">
                        <div className="col-lg-8 col-md-7">
                            <Accordion defaultActiveKey={['0']} alwaysOpen>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What is STOR's primary investment focus?</Accordion.Header>
                                    <Accordion.Body>
                                        STOR's primary focus is on commercial real estate, with an emphasis on self-storage and assisted living opportunities.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>How does STOR accommodate medical professionals, specifically doctors?</Accordion.Header>
                                    <Accordion.Body>
                                        STOR conscientiously acknowledges the distinctive financial pathways and challenges faced by doctors and other medical professionals. We approach investment strategies with a genuine consideration for their unique professional and financial landscapes, endeavoring to provide thoughtful and individualized financial advice without overstepping into assured outcomes. Our commitment lies in offering a reliable and informed foundation upon which they can build their financial future, always prioritizing ethical engagement and transparent practices.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Who are the key team members at STOR?</Accordion.Header>
                                    <Accordion.Body>
                                        Dr. Joseph Ryan Smolarz (Founder & CEO) and Martin Taradejna (Chief Marketing Officer) lead the team. Their bios can be found on the About Us page.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>How do I get started with investing in STOR?</Accordion.Header>
                                    <Accordion.Body>
                                        You can start by exploring the investment process detailed on our site and then reach out to us via the Contact Us form for personalized guidance.
                                    </Accordion.Body>
                                </Accordion.Item>
                                {/* <Accordion.Item eventKey="4">
                                    <Accordion.Header>What are the long-term benefits of investing with STOR?</Accordion.Header>
                                    <Accordion.Body>
                                        STOR offers a robust investment platform with flexibility, focusing on maximizing returns through unique deal structuring in self-storage and other commercial real estate opportunities.
                                    </Accordion.Body>
                                </Accordion.Item> */}
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>How does STOR approach acquisitions and operations?</Accordion.Header>
                                    <Accordion.Body>
                                        The management team at STOR has prior experience in commercial real estate, including acquisition, conversion, and operation, from roles prior to the establishment of the fund. STOR, as a new fund, will utilize this experience in its approach to acquisitions and operations.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>Can I learn more about self-storage investments?</Accordion.Header>
                                    <Accordion.Body>
                                        Yes, STOR provides educational content about self-storage investments, including strategies tailored for medical professionals.
                                    </Accordion.Body>
                                </Accordion.Item>


                                <Accordion.Item eventKey="7">
                                    <Accordion.Header>Who can I contact for more information?</Accordion.Header>
                                    <Accordion.Body>
                                        You can reach out to us via the Contact Us page on our website. Our team is available to assist you with any inquiries.
                                    </Accordion.Body>
                                </Accordion.Item>
                                {/* <Accordion.Item eventKey="8">
                                    <Accordion.Header>Is there any patented innovation behind SToR?</Accordion.Header>
                                    <Accordion.Body>
                                        Yes, Dr. Joseph Ryan Smolarz, the Founder & CEO, has developed patents in the medical field, reflecting an innovative approach within the organization.
                                    </Accordion.Body>
                                </Accordion.Item> */}


                                <Accordion.Item eventKey="9">
                                    <Accordion.Header>How does STOR maintain investor relations?</Accordion.Header>
                                    <Accordion.Body>
                                        STOR prioritizes investor relations and aims to offer clear communication and build relationships with stakeholders. We are committed to providing transparency to our investors by sharing relevant information and updates, adhering to regulatory requirements, and being available for inquiries and discussions to address their questions and concerns.
                                    </Accordion.Body>
                                </Accordion.Item>

                            </Accordion>
                        </div>
                        <div className="col-lg-4 col-md-5">
                            <div className="lightBlue">
                                <div className="lightBlue-img">
                                    {/* <img src={BlueImgIn} alt="blue in img" /> */}
                                    <img src={BlueImgIn} alt="blue in img" />
                                </div>

                                {/* <div className="smallhead">Do you have more questions?</div>
                                <p className='txt mb-0'>We’ve cracked the code that gets you glued to investing to secure a bright future</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default FAQ