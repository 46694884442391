import axios from "axios";

const getHeaders = function () {
    return {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `*`,
    }
};

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        config.headers = getHeaders();

        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    function (response) {
        // Do something with response data
        return response;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export { axiosInstance };
